<script setup>
import Button from '../design-system/button.vue';
import FacebookIcon from '../design-system/icons/FacebookIcon.vue';
import TwitterIcon from '../design-system/icons/TwitterIcon.vue';
import WhatsappIcon from '../design-system/icons/WhatsappIcon.vue';
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '~~/stores/auth.store';
import { useGameStore } from '~/stores/games.store';
import { trackingMethods } from '~/plugins/analytics.client';

const { $t } = useNuxtApp();

const game = useGameStore();
const auth = useAuthStore();
const gameUrl = location.href;

let copied = ref(false);

function copyLink() {
  let url = location || location.origin;
  navigator.clipboard.writeText(url);
  copied.value = true;
  setTimeout(() => (copied.value = false), 1200);
  return url;
}

function emailLink() {
  window.open(`mailto:?subject=${$t('email-game-share-text')}${gameUrl}`);

  // send GA tracking method when game has been shared on email
  trackingMethods.socialShare(
    game.currentGame.name,
    auth.currentUser.username,
    'email'
  );
}

function whatsappLink() {
  window.open(
    `https://wa.me/?text=${$t('whatsapp-game-share-text') + '%0a' + gameUrl}`,
    '_blank'
  );
  trackingMethods.socialShare(
    game.currentGame.name,
    auth.currentUser.username,
    'whatsapp'
  );
}

function facebookLink() {
  window.open(`https://www.facebook.com/sharer.php?display=popup&u=${gameUrl}`);

  // send GA tracking method when game has been shared on facebook
  trackingMethods.socialShare(
    game.currentGame.name,
    auth.currentUser.username,
    'facebook'
  );
}
function twitterLink() {
  window.open(
    `https://twitter.com/intent/tweet?url=${gameUrl}&text=${$t(
      'twitter-game-share-tweet-text'
    )}&related='@JackpotcityZA'`,
    '_blank'
  );

  // send GA tracking method when game has been shared on twitter
  trackingMethods.socialShare(
    game.currentGame.name,
    auth.currentUser.username,
    'twitter'
  );
}
</script>

<template>
  <p class="py-2 text-base text-black dark:text-white w-full text-center">
    {{ $t('share-this-game-with-friends') }}
  </p>

  <div class="flex flex-row justify-center pb-4">
    <div class="px-1">
      <Button
        class="text-black dark:text-white mr-2 dark:bg-dark-900 bg-light-200"
        type="neutral"
        size="icon-lg"
        @click="whatsappLink()"
        share
      >
        <WhatsappIcon class="fill-dark-900 dark:fill-current" />
      </Button>
    </div>
    <div class="px-1">
      <Button
        class="text-black dark:text-white mr-2 dark:bg-dark-900 bg-light-200"
        type="neutral"
        size="icon-lg"
        @click="facebookLink"
        share
      >
        <FacebookIcon class="fill-dark-900 dark:fill-current" />
      </Button>
    </div>

    <div class="px-1">
      <Button
        class="text-black dark:text-white mr-2 dark:bg-dark-900 bg-light-200"
        type="neutral"
        size="icon-lg"
        @click="twitterLink"
        share
      >
        <TwitterIcon class="fill-dark-900 dark:fill-current" />
      </Button>
    </div>
    <div class="px-1">
      <Button
        class="text-black dark:text-white mr-2 dark:bg-dark-900 bg-light-200"
        type="neutral"
        size="icon-lg"
        @click="emailLink"
        share
      >
        <EnvelopeIcon class="w-8 stroke-dark-900 dark:stroke-current" />
      </Button>
    </div>
  </div>
  <div v-if="copied" class="copied-text">Copied!</div>
</template>

<style scoped>
.slide-up-fade-enter-active {
  transition: all 150ms ease-out;
}

.slide-up-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-fade-enter-from,
.slide-up-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.copied-text {
  position: absolute;
  bottom: 2rem;
  left: 80%;
  z-index: 5;
  display: block;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
</style>
